var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table-page", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.isLoaded,
        expression: "isLoaded",
      },
    ],
    attrs: {
      tableName: _vm.$options.name,
      columns: _vm.columns,
      filterModel: _vm.filterModel,
      headerTitle: "groupsofattract3414",
      tooltipMsg: "pimanualofgroup",
      requestFunction: _vm.requestFunction,
      defaultOrderBy: { column: "AgroupId", ascending: 0 },
      modelId: "AgroupId",
      addNewRecordMsg: "",
    },
    on: {
      editRecord: function ($event) {
        return _vm.redirectToDetail($event)
      },
      filterChanged: function ($event) {
        return _vm.handleFilter($event)
      },
      removeFilters: function ($event) {
        return _vm.removeAllFilters($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }