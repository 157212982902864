import i18n from '@/services/lang';

export const tableColumns = [
	{
		model: 'AgroupNameSk',
		i18n: 'namesk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AgroupH1Sk',
		i18n: 'hnamesk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AgroupUrlNameSk',
		i18n: 'urlsk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AgroupNameCz',
		i18n: 'namecz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AgroupH1Cz',
		i18n: 'hnamecz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AgroupUrlNameCz',
		i18n: 'urlcz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AgroupNamePl',
		i18n: 'namepl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AgroupH1Pl',
		i18n: 'hnamepl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AgroupUrlNamePl',
		i18n: 'urlpl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AgroupNameEn',
		i18n: 'nameen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AgroupH1En',
		i18n: 'hnameen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AgroupUrlNameEn',
		i18n: 'urlen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AgroupNameDe',
		i18n: 'namede',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AgroupH1De',
		i18n: 'hnamede',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AgroupUrlNameDe',
		i18n: 'urlde',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AgroupNameHu',
		i18n: 'namehu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AgroupH1Hu',
		i18n: 'hnamehu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AgroupUrlNameHu',
		i18n: 'urlhu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
];
